import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigator } from '../../routing/navigator';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Layout } from '../../shared/components/layout/Layout';
import { useEffect } from 'react';
import { css } from '@emotion/css';
import { h2Style, p2Style } from '../../shared/styles/text';
import { Colors } from '../../shared/styles/colors';

export const TermsOfUsePage = (): JSX.Element => {
  const navigator = useNavigator();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout.Content className={bodyStyle}>
      <Button
        startIcon={<ChevronLeftIcon className={iconStyle} />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <h1>Uvjeti korištenja web stranice marex-hc.com</h1>
      <h2 id={'last-update'}>Last Updated: October 30, 2020</h2>
      <p>
        Svako korištenje ove web stranice podložno je dolje navedenim uvjetima i
        odredbama. Ovi uvjeti korištenja vrijede za sve posjetitelje web
        stranice.
      </p>
      <p>
        Korištenjem Internet stranice www.marex-es.hr (dalje u tekstu: Vlasnik
        stranice) smatra se da su korisnici u svakom trenutku upoznati s ovim
        Uvjetima korištenja web stranice te da ih u potpunosti razumiju i
        prihvaćaju, uključujući odredbe o obradi podataka i pravila u vezi s
        kolačićima. Korištenjem sadržaja web stranice Vlasnika stranice korisnik
        prihvaća sve eventualne rizike koji nastaju korištenjem ove web
        stranice, te prihvaća koristiti sadržaj ove web stranice isključivo za
        osobnu uporabu i na vlastitu odgovornost.
      </p>
      <h2>Općenito</h2>
      <p>
        Ovi Opći uvjeti poslovanja dio su obveze Prodavatelja sukladno odredbama
        Zakona o zaštiti potrošača te služe tome da Kupac prije nego sklopi
        ugovor na daljinu bude na jasan i razumljiv način obaviješten o nizu
        okolnosti bitnih za zaključenje, izvršavanje, raskid ugovora, a sve
        sukladno zakonskom uređenju. Stoga, ukoliko želite biti naš Kupac,
        molimo Vas da pažljivo pročitate ove Opće uvjete poslovanja i da
        poslujete s nama samo ako se slažete sa svim točkama i ako ćete
        pročitano i primijeniti u našoj suradnji.
      </p>
      <p>Pojam Prodavatelj odnosi se na trgovačka društva:</p>
      <ul>
        <li>
          Marex Elektrostroj d.o.o. Franje Krežme 6, 23000 Zadar, Hrvatska, OIB:
          85315313291, M: +385 23 341 016
        </li>
        <li>
          Lučka Uprava Rabac, Obala M. Tita 47c, 52221 Rabac, OIB 43940243528,
          M: +385 52 872 160
        </li>
        <li>
          Grad Omiš, Trg kralja Tomislava 5,21310 Omiš Croatia, OIB 49299622160
        </li>
        <li>
          Lučka Uprava Rab, Obala kralja Petra Krešimira IV 7, Rab 51280,
          Croatia, OIB 17820392036, M: +385 51 725 938
        </li>
      <li>
          ČISTOĆA I ZELENILO KONAVLE d.o.o., Bistroće 70, Čilipi, 20215, Croatia, OIB 10165352762
          M: +385 20 771 022
      </li>
      </ul>
      <p>
        Pojam Kupca odnosi se kako na potrošače (svaku fizičku osobu koja sklapa
        pravni posao ili djeluje na tržištu izvan svoje trgovačke, poslovne,
        obrtničke ili profesionalne djelatnosti), tako i na pravne osobe,
        obrtnike i fizičke osobe koje obavljaju poslovne djelatnosti, no oni su
        zaštićeni odredbama Zakona o zaštiti potrošača samo ako kupuju proizvode
        nevezano za svoje poslovne, gospodarske djelatnosti.
      </p>
      <h2>Uvjeti korištenja i odricanje od odgovornosti</h2>
      <p>
        Vlasnik stranice se u potpunosti odriče svake odgovornosti koja na bilo
        koji način može nastati iz, ili je na bilo koji način vezana za
        korištenje ove web stranice, za bilo koje radnje korisnika uporabom ili
        zlouporabom sadržaja ove web stranice, te za bilo kakvu štetu koja može
        nastati korisniku ili bilo kojoj trećoj strani u vezi s uporabom ili
        zlouporabom korištenja sadržaja ove web stranice.
      </p>
      <p>
        Vlasnik stranice zadržava pravo izmjene sadržaja i uvjeta korištenja web
        stranice bez obaveze prethodne najave te nije odgovoran za bilo kakvu
        štetu nastalu tim promjenama.
      </p>
      <p>
        Vlasnik stranice nastoji održati sve funkcionalnosti web stranice, no ne
        može jamčiti da će sve funkcionalnosti biti uvijek dostupne niti
        odgovara za eventualnu nedostupnost stranice kao ni za štetu koja bi
        mogla nastati zbog prekida dostupnosti, neobjavljivanja odnosno brisanja
        sadržaja ili greške u radu stranice.
      </p>
      <p>
        Vlasnik stranice ne odgovara za sadržaje niti za raspoloživost drugih
        stranica na koje upućuje poveznicama.
      </p>
      <h2>Uvjeti korištenja sadržaja</h2>
      <p>
        Korištenjem sadržaja web stranice Vlasnik stranice smatra se da su
        korisnici upoznati s ovim uvjetima i svim rizicima koji nastaju iz
        korištenja ove web stranice te prihvaćaju koristiti sadržaj ove web
        stranice isključivo u nekomercijalne svrhe, za osobnu uporabu i na
        vlastitu odgovornost.
      </p>
      <p>
        Vlasnik stranice se ne može se smatrati odgovornim za bilo kakvu štetu
        nastalu korištenjem bilo kojeg dijela sadržaja.
      </p>
      <p>
        Informacije na ovoj internet stranici informativnog su sadržaja i
        Vlasnik stranice zadržava pravo promjene ili ukidanja bilo kojeg
        sadržaja na portalu bez prethodne najave.
      </p>
      <p>
        Vlasnik stranice ne odgovara ni za kakve posljedice prouzrokovane
        promjenama sadržaja.
      </p>
      <p>
        Također, Vlasnik stranice nije odgovoran za eventualne štete nastale
        zbog tipografskih pogrešaka.
      </p>
      <h2>Zaštita autorskim pravom</h2>
      <p>
        Vlasnik stranice polaže autorska prava na sve vlastite sadržaje
        (tekstualne, vizualne, baze podataka i programerski kod).
      </p>
      <p>
        Neovlašteno korištenje bilo kojeg dijela web stranice bez dozvole
        vlasnika autorskih prava, smatra se kršenjem autorskih prava Vlasnika
        stranice.
      </p>
      <p>
        Dokumenti, fotografije, podaci, informacije kao i bilo koji dio sadržaja
        objavljeni na stranici ne smiju se reproducirati, distribuirati ili na
        bilo koji način koristiti u komercijalne svrhe bez dopuštenja Vlasnika
        stranice.
      </p>
      <p>
        Cjelokupni sadržaj objavljen na web stranici Vlasnika stranice zaštićen
        je autorskim pravima.
      </p>
      <p>
        Neovlašteno korištenje bilo kojeg dijela web stranice Vlasnika stranice,
        smatra se kršenjem autorskih prava i podložno je zakonskoj sankciji.
      </p>
      <p>
        Dokumenti, podaci i informacije objavljeni na web stranicama Vlasnika
        stranice ne smiju se reproducirati, distribuirati ili na bilo koji način
        koristiti u komercijalne svrhe bez izričitog pristanka Vlasnika stranice
        ili na bilo koji način koji može uzrokovati štetu Vlasnika stranice ili
        bilo kojoj trećoj strani.
      </p>
      <p>
        Dokumenti, podaci i informacije objavljeni na ovim web stranicama mogu
        se koristiti samo za individualne potrebe korisnika uz poštivanje svih
        autorskih prava.
      </p>
      <p>
        Dopušteno je stavljati poveznicu web stranice Vlasnika stranice na druge
        stranice.
      </p>
      <p>
        Vlasnik stranice nije odgovoran za zakonitost sadržaja i upravljanja web
        stranica na koje se spajate s web stranice Vlasnika stranice.
      </p>
      <p>
        Nastojimo korištenje naše web stranice kao i Interneta uopće, učiniti
        sigurnim. Stoga ćemo Vam biti zahvalni obavijestite li nas o bilo kakvom
        nezakonitom ili nemoralnom sadržaju na web stranicama vezanih na našu
        stranicu.
      </p>
      <p>
        1. Koji Vaši podaci nam trebaju i zašto? Marex Elektrostroj d.o.o.,
        Franje Krežme 6, 23000 Zadar, Hrvatska, OIB: 85315313291, koristi vaš
        e-mail, ime i prezime, registraciju, godinu rođenja OIB, adresu i broj
        telefon kod kreiranja računa za upotrebu resursa unutar marina. Ovi
        podaci su nam potrebni da bi Vam mogli dati dopuštenje za korištenje
        struje i vode unutar marina. Tehnički podaci poput ip-adrese i cookie se
        koriste kratkoročno i služe za tehničko poboljšanje vašeg doživljaja
        aplikacije.
      </p>
      <p>
        2. Tko sve ima pristup vašim podacima? Pristup Vašim osobnim podacima
        ima samo dio naših zaposlenika koji rade u odjelima korisničke podrške i
        administracije sustava. Navedene kategorije zaposlenika su upoznate sa
        našom politikom i procedurama zaštite osobnih podataka i dužne su
        postupati po istima.
      </p>
      <p>
        3. Da li ćemo vaše podatke dijeliti s drugima? Vaši podaci će se
        dijeliti sa drugima samo u okviru potrebnom za izvršanje naše usluge.
        Vaši podaci se dijele sa:
        <ul>
          <li>
            WSPay payment gateway-om za obradu plaćanja kreditnim karticama
          </li>
          S našim partnerima:
          <li>
            Lučka Uprava Rabac, Obala M. Tita 47c, 52221 Rabac, OIB 43940243528
          </li>
          <li>
            Grad Omiš, Trg kralja Tomislava 5,21310 Omiš Croatia, OIB
            49299622160
          </li>
          <li>
            Lučka Uprava Rab, Obala kralja Petra Krešimira IV 7, Rab 51280,
            Croatia, OIB 17820392036
          </li>
          <li>
            ČISTOĆA I ZELENILO KONAVLE d.o.o., Bistroće 70, Čilipi, 20215, Croatia, OIB 10165352762
          </li>
        </ul>
        <br />
        Vaši podaci su kod nas sigurni i nećemo ih djeliti, prodavati ili
        ustupati drugim privatnim ili pravnim osobama za druge namjene osim gore
        navedenih.
      </p>
      <p>
        4. Koliko dugo ćemo čuvati vaše podatke? Tehnički podaci poput adrese i
        cookie-a se cuvaju 30 dana. Ostali osobni podaci se čuvaju 3 godine od
        vašeg zadnjeg logina ili dok ne zatražite da ih uklonimo.
      </p>
      <p>
        5. Kako smo došli do vaših podataka? Sve navedene osobne podatke ste nam
        dali sami pri kupnji osiguranja na našoj stranici Marex.hc. Marex
        Elektrostroj d.o.o. ni na koji način ne prikuplja kupuje/nabavlja vaše
        osobne podatke od trećih osoba.
      </p>
      <p>
        6. Da li i možete zatražiti da zauvijek uklonimo Vaše podatke i povući
        svoju privolu za njihovo korištenje? Da, kada god želite možete povući
        privolu za korištenje svojih osobnih podataka i/ili od nas zatražiti da
        izbrišemo sve Vaše podatke iz našeg sustava. Pošaljite nam email na
        info@marex-es.hr i pokrenuti cemo proceduru za uklanjanje svih osobnih
        podataka koja ce biti gotova u roku od 15 dana.
      </p>
      <p>
        7. Da li možete vidjeti koje Vaše osobne podatke imamo i možete li ih
        ispraviti? Da, u svakom trenutku možete zatražiti informaciju koje sve
        podatke koje o vama imamo i možete ih izmijeniti slanjem zahtjeva na
        email info@marex-es.hr
      </p>
      <p>
        8. Da li možete zatražiti eksport svih svoji podataka koji se nalaze u
        našem sustavu? Da, u svakom trenutku možete zatražiti da vam
        eksportiramo podatke slanjem zahtjeva na email info@marex-es.hr
      </p>
      <p>
        9. Da li se moji podaci automatski profiliraju i zašto? U ovisnosti o
        vašoj prošloj kupnji dobiti ćete i novu ponudu. Isključiva svrha
        profiliranja je unapređenje naše usluge i vašeg doživljaja korištenja
        portala. Ova automatizirana obrada ne utječe značajno na vas i ne
        proizvodi nikakve pravne učinke. Navedeno profiliranje se ne dijeli s
        trećim osoba niti na koji način ne utječe na konačne opcije kako i
        koliko možete koristiti naše usluge. Od profiliranje se možete izuzeti
        slanjem emaila na info@marex-es.hr
      </p>
      <p>
        10. Kako stranka daje pristanak da mu se dostavljaju informacije i
        ponude o obavezno osiguranju? Pri unosu podataka za izračun ponude ao
        klikom na kućicu GDPR suglasnost daje i pristanak te potvrđuje da je
        pročitao uvjete i pravilnik o GDPR-u te da je elektorsnki obaviješten o
        istom.
      </p>
      <p>
        11. Kome se mogu žaliti ako mislim da kršite moje pravo na privatnost?
        Možete se žaliti Agenciji za zaštitu prava potrošača i to na linku
        ovdje(http://azop.hr/)
      </p>
    </Layout.Content>
  );
};

const bodyStyle = css`
  padding-top: 0;
  > p {
    ${p2Style}
    margin-top:0.7rem;
  }
  > h2 {
    ${h2Style}
    margin-top: 2rem;
  }
  > h2#last-update {
    ${p2Style}
    color: ${Colors.WINDOW_GRAY};
    padding: 1rem 0rem;
    margin-top: 0px;
  }
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;

const iconStyle = css`
  width: 1.5rem;
`;
