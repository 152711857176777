import { configuration } from '../../utils/configuration';
import { http } from '../http';

export const handlePaymentSubmit = async (
  amount: string,
  locationId: number,
): Promise<void> => {
  const paymentOrderData = await http.createPaymentOrder({
    amount,
    locationId: locationId,
    clientUrl: configuration.FRONTEND_URL || '',
  });

  const formData = {
    ShopID: paymentOrderData.shopId,
    ShoppingCartID: Number(paymentOrderData.id).toString(),
    Version: paymentOrderData.version,
    TotalAmount: paymentOrderData.amount,
    Signature: paymentOrderData.signature,
    ReturnURL: paymentOrderData.returnURL,
    ReturnErrorURL: paymentOrderData.returnErrorURL,
    CancelURL: paymentOrderData.cancelURL,
  };
  const submitForm = document.createElement('form');
  submitForm.method = 'POST';
  submitForm.action = `${configuration.WS_PAY_URL}/authorization.aspx`;
  // ONLY FOR NEW locations current Cavtat
  // if(locationId && locationId === 12) {
  //   submitForm.action = `https://formtest.wspay.biz/authorization.aspx`;
  // }

  Object.entries(formData).map(([key, value]) => {
    const customInput = document.createElement('input');
    customInput.type = 'hidden';
    customInput.name = key;
    customInput.value = value;
    submitForm.appendChild(customInput);
  });
  document.body.appendChild(submitForm);
  submitForm.submit();
};
